<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit" :api="url" :typeObject="objectType" :initData="initData"></edit-dialog>
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="false"
      :defaults="defaults"
      @click1="onClickRow($event)"
      :selectedRows="selectedRows"
      @click="
        counter += 1;
        onDetectClick($event);
      "
    >
      <template v-slot:item.actions="{ row }">
        <div class="d-flex">
          <v-btn v-if="row.link_ref" elevation="0" x-small fab class="ma-0 ml-1" color="green" title="Перейти по ссылке" @click.stop="openLink(row)">
            <v-icon>fas fa-external-link-alt</v-icon>
          </v-btn>
          <v-btn elevation="0" x-small fab class="ma-0 ml-1" color="grey" title="Создать копию" @click.stop="makeCopy(row)">
            <v-icon>fas fa-copy</v-icon>
          </v-btn>
        </div>
      </template>
    </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, keyDetect, doubleClickDetect, genModel],
  components: {
    editDialog: () => import("./dialogs/objectsAgentDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.objects,
      initData: null,
    };
  },
  created() {
    this.$root.title = this.$route.meta.title; //"Агентские объекты";
    //this.objectType = this.$route.meta.objectType;
  },
  computed: {
    defaults() {
      return {
        sort: { key: "id", order: "DESC" },
        filters: {},
        paramName: this.objectType,
      };
    },
    objectType() {
      return this.$route.meta.objectType;
    },
    config() {
      return this.m?.config?.[this.objectType] || {};
    },
    url() {
      return this.config.api;
    },

    model() {
      let res = this.getModelList(this.m, "listAgent", false);
      res.find((m) => m.name == "status").options = res.find((m) => m.name == "status").options.filter((s) => s.type == this.objectType);

      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    openLink(row) {
      window.open(row.link_ref, "_blank");
    },
    onSingleClick(d) {},
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    onClickRow(d) {
      if (d.field.name == "actions") return;
      this.$router.push({ name: this.config.viewName, params: { id: d.row.id } });
      // this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.initData = null;
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    async makeCopy(r) {
      if (!r.id) return;
      let response;
      try {
        response = await this.$axios.get(this.url + "/" + r.id);
        let data = response.data.data;
        this.initData = data;
        this.showEditDialogFun(0);
      } catch (error) {}
    },
  },
};
</script>
